@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'RixInooAriDuriR';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2207-01@1.0/RixInooAriDuriR.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "aAbstractGroovy";
    src: url('../../public/assets/font/aAbstractGroovy.ttf');
}

@font-face {
    font-family: 'ONE-Mobile-Title';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-Title.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@media (min-width: 375px) {
    .App,
    .ShowNav,
    .Nav,
    .bgAniContainer {
        width: 375px;
    }
}

@media (max-width:500px) {
    .App,
    .ShowNav,
    .Nav,
    .bgAniContainer {
        width: 100vw;
    }
}

#root {
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}


/* 폰트 */


/* 메인폰트 */

.titleFont {
    font-family: 'RixInooAriDuriR';
    -webkit-text-stroke: 2px black;
    text-shadow: 4px 5px 3px black;
}

.groovyFont {
    font-family: "aAbstractGroovy";
    -webkit-text-stroke: 2px black;
    text-shadow: 4px 5px 3px black;
}


/* 서브제목 폰트 */

.subtitleFont {
    font-family: 'RixInooAriDuriR';
    -webkit-text-stroke: 1px black;
    text-shadow: 2px 3px 2px black;
}

.subGroovyFont {
    font-family: "aAbstractGroovy";
    -webkit-text-stroke: 2px black;
}


/* 텍스트 폰트 */

.textRixFont {
    font-family: 'RixInooAriDuriR';
    -webkit-text-stroke: 0.5px black;
    text-shadow: 1px 1px 1px black;
    letter-spacing: 0.05em;
}

.textRixFontOrange {
    font-family: 'RixInooAriDuriR';
    -webkit-text-stroke: 0.5px #FD9F28;
    text-shadow: 1px 1px 1px #FD9F28;
    letter-spacing: 0.05em;
}

.textRixFontblue {
    font-family: 'RixInooAriDuriR';
    -webkit-text-stroke: 1px #2E3D86;
    letter-spacing: 0.05em;
}

.titleShadowXX {
    font-family: 'RixInooAriDuriR';
}

.groovyFontXX {
    font-family: "aAbstractGroovy";
}

.textFont {
    font-family: 'ONE-Mobile-Title';
}

.SASFont {
    font-family: 'RixInooAriDuriR';
    -webkit-text-stroke: 1px black;
}

.bg-2E3D86 {
    background-color: #2E3D86;
    text-shadow: 4px 3px 0px rgba(0, 0, 0, 1);
}

.App {
    min-height: 100vh;
    background-color: rgb(0, 157, 255);
    /* background-image: url('../public/img/background.png'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    color: white;
}

.BackBox {
    background-color: rgba(255, 255, 255, 0.2);
    width: 90%;
    height: 90%;
    margin: auto auto;
    border-radius: 15px;
}

.BackBoxBlack {
    background-color: rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 90%;
    margin: auto auto;
    border-radius: 15px;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: 'SF_HailSnow'; */
    min-height: 100vh;
    margin: 0;
}

.last {
    height: 12vh;
}


/* FixedBottom  */

.FixedBottom {
    letter-spacing: 7px;
}


/* background animation */
.bgAniContainer{
  width: 500px;
}

.bgAniText {
    animation: textLoop 20s linear infinite;
    padding-right: 1.4881vw;
}

@keyframes textLoop {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}