.pbox {
    width: 110px;
    height: 160px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    margin: 5px;
    letter-spacing: 1px;
    padding: 5px;
}

.Pimg {
    height: 70px;
    width: auto;
}

.pbox span {
    display: block;
}

.Pinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3px;
    align-items: center;
}

.Pinfo img {
    height: 18px;
    width: auto;
    margin-right: 5px;
}

.Pinfo span {
    font-size: 5px;
}

.Pdepart {
    margin: 0;
    font-size: 9px;
    color: #7193c3;
    letter-spacing: 1px;
}

.Pposi {
    font-size: 9px;
    margin: 0;
}

hr {
    margin: 0.2rem;
}

.Prole {
    font-size: 10px;
}

.gridpeople {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.likelion-color {
    color: #7193c3;
}

.renew-color {
    color: #b69414;
}