.ShowNav {
    min-height: 100vh;
    background-color: rgb(0, 157, 255);
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}

.navLinkItem {
    color: white;
    text-decoration: none;
    -webkit-text-stroke: 1px black;
}

.navLinkItem:visited {
    color: inherit;
    text-decoration: inherit;
    -webkit-text-stroke: 1px black;
}

.NavBtn {
    position: relative;
    z-index: 100;
}

.nav-bg-scrolled {
    background-color: rgb(251, 244, 232, 0.1);
    backdrop-filter: blur(4px);
}

.moveHome {
    color: #ff9100;
    -webkit-text-stroke: 1px black;
    position: relative;
    z-index: 100;
}

.menugreen {
    background-color: white;
}

.hamburger-menu {
    height: 40px;
    width: 35px;
    position: relative;
    padding: 1rem;
}

.ham-bar {
    width: 70%;
    height: 3px;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform .6s, opacity .8s, top .6s;
}

.bar-top {
    top: 25%;
}

.bar-bottom {
    top: 75%
}

.hamburger-menu.active .bar-top {
    transform: translate(-50%, -50%) rotate(-315deg);
    top: 50%
}

.hamburger-menu.active .bar-mid {
    opacity: 0;
}

.hamburger-menu.active .bar-bottom {
    transform: translate(-50%, -50%) rotate(-225deg);
    top: 50%;
}

.off-screen-menu {
    top: 0;
    left: 0;
    background-color: white;
    height: 100vh;
    width: 100%;
    opacity: 0;
    transition: opacity .6s ease-in-out;
    z-index: 30;
}

.off-screen-menu.open {
    opacity: 1;
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}