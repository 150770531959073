.TimeTable {
    background-image: url('../../public/assets/images/background/timetableBack.png');
    background-position: center;
    background-size: cover;
    min-height: 100vh
}

.PresentList {
    background-image: url('../../public/assets/images/background/presentListBack2.png');
    background-position: center;
    background-size: cover;
    min-height: 100vh
}

.FunHola {
    background-image: url('../../public/assets/images/background/funHolaBack.png');
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}

.HotHola {
    background-image: url('../../public/assets/images/background/hotHolaBack.png');
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}

.MainSong {
    background-image: url('../../public/assets/images/background/mainSongBack.png');
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}

.SnsEvent {
    background-image: url('../../public/assets/images/background/snsEventBack.png');
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}

.GuestPage {
    background-image: url('../../public/assets/images/background/secretGuestBack.png');
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}
.About {
  background-image: url('../../public/assets/images/background/aboutBack.png');
  background-position: center;
  background-size: cover;
  min-height: 100vh
}

/* Main Song */

.orange {
    color: #FD9F28;
}

.eventBox {
    border: 1px solid black;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 5px 5px 2px 2px rgb(0, 0, 0, 0.7);
    background-color: white;
}

.guestText {
    -webkit-text-stroke: 1px #2E3D86;
    text-shadow: 3px 4px 3px #2E3D86;
}

.guestText1 {
    -webkit-text-stroke: 1px #1187CF;
    text-shadow: 3px 4px 3px #1187CF;
}